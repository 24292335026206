import { resolveUrl } from 'utils/url'

const isStorybook = process.env.STORYBOOK === 'true'

/**
 * amount in seconds after which a page re-generation can occur. Defaults to false.
 * When revalidate is false it means that there is no revalidation, so the page will be cached as built until your next build.
 * @see https://nextjs.org/docs/basic-features/data-fetching#incremental-static-regeneration
 */
export const REVALIDATION_TIMEOUT: number | false = parseRevalidate(
  process.env.REVALIDATION_TIMEOUT,
  30,
)
export const REVALIDATION_ERROR_TIMEOUT: number | false = parseRevalidate(
  process.env.REVALIDATION_ERROR_TIMEOUT,
  5000,
)
export const ORIGIN_URL = isStorybook
  ? window.location.origin
  : process.env.NEXT_PUBLIC_SELF_URL || process.env.NEXT_PUBLIC_BASE_URL || ''
export const CMS_API_BASE_URL = process.env.NEXT_PUBLIC_CMS_API_URL!
export const NEST_API_URL = process.env.NEXT_PUBLIC_NEST_API_URL!
export const IS_DEVELOPMENT_MODE = process.env.NODE_ENV === 'development'
export const IS_PRODUCTION = process.env.NODE_ENV === 'production'
export const NEXT_PROCESS_STEP = process.env.NEXT_PROCESS_STEP!
export const IS_BUILD_NEXT_STEP = NEXT_PROCESS_STEP === 'build'
export const IS_START_NEXT_STEP = NEXT_PROCESS_STEP === 'start'
export const NEXT_PROXY_TO_NEST_WITH_AUTH_COOKIE_URL = resolveUrl(ORIGIN_URL, '/next-api/main')
export const AUTH_TOKEN_COOKIE_NAME = 'wdw2nnk24bc'
export const IS_AUTH_COOKIE_NAME = 'isAuth'
export const BASE_NEST_URL = IS_BUILD_NEXT_STEP
  ? NEST_API_URL
  : NEXT_PROXY_TO_NEST_WITH_AUTH_COOKIE_URL
export const GOOGLE_ANALYTICS_ID = process.env.GOOGLE_ANALYTICS_ID
export const META_PIXEL_ID = process.env.META_PIXEL_ID
export const NEXT_PUBLIC_AUTHORIZE_NET_ACCEPT_CLIENT_KEY =
  process.env.NEXT_PUBLIC_AUTHORIZE_NET_ACCEPT_CLIENT_KEY
export const NEXT_PUBLIC_AUTHORIZE_NET_ACCEPT_API_LOGIN_ID =
  process.env.NEXT_PUBLIC_AUTHORIZE_NET_ACCEPT_API_LOGIN_ID
export const GOOGLE_SITE_VERIFICATION_ID = process.env.GOOGLE_SITE_VERIFICATION_ID
export const MAS_API_TOKEN = process.env.MAS_API_TOKEN

function parseRevalidate(value: string | undefined, defaultValue: number | false): number | false {
  if (value === 'false') return false
  return Number(value) || defaultValue
}
